<template>
    <div class="authpage flex">
        <notify></notify>
        <div class="logo"></div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">
                    Авторизация
                </div>
                <div class="form-label">
                    <div class="t">БИН/ИИН/ОГРН:</div>
                    <input class="login" name="login" v-model="form.login">
                </div>
                <div class="form-label">
                    <div class="t">Пароль:</div>
                    <input class="password" name="password" type="password" v-model="form.password">
                </div>
                <div class="btn blue" @click="sendForm">
                    Войти
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import notify from "./panel/components/notify";

export default {
    name: 'Auth',
    components: {notify},
    data() {
        return {
            form: {
                login: '',
                password: ''
            }
        }
    },
    methods: {
        sendForm() {
            let self = this;
            this.$api.post(`auth`, this.form).then(res => {
                if (res.data.success) {
                    self.$store.commit('auth', res.data.data);
                    const nextUrl = sessionStorage.getItem('nextUrl');
                    if (nextUrl) {
                        this.$router.push(nextUrl);
                    } else {
                        this.$router.push('/panel/calendar');
                    }
                } else {
                    self.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        }
    },
    created() {
        if (this.$router.currentRoute.value.name == 'logout') {
            this.$store.commit('logout');
            this.$router.push('/');
        }
    }
}
</script>

<style lang="scss">
.authpage {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

    .card {
        max-width: 500px;
        width: 100%;
    }

    .logo {
        background: url("../assets/img/logo.png") no-repeat center / contain;
        width: 175px;
        height: 50px;
    }
}
</style>
